import { toRefs, ref,onMounted,reactive, computed } from 'vue';
import locations from '@/assets/data/location.json'
import countries from '@/assets/data/countries.json'

export function useSetting() {
   
    function getImageFolder(type,data_id){
        let host = `${process.env.VUE_APP_API_HOST}/storage/uploads`
        if(type == 'event_cover'){
            return `${host}/event/${data_id}/cover`
        }
        if(type == 'event_content'){
            return `${host}/event/${data_id}/content`
        }
        if(type == 'company_cover'){
            return `${host}/company/${data_id}/cover`
        }
        if(type == 'company_logo'){
            return `${host}/company/${data_id}/logo`
        }
    }
    function getAreaData(){
        return {
            cities:locations["cities"],
            districts:locations["detail"],
        }
    }
    function getCountryData(){
        return countries
    }
    function mobileCodeToCountry(code){
        let selectedCountry = countries.find(x => x.phoneCode==code)
        return selectedCountry.countryCode || ''
    }
    return {getImageFolder ,getAreaData,getCountryData,mobileCodeToCountry};
}
