
export default [
    {
        path: '/event',
        redirect:'/event/list',

        props: true,
        meta: { 
            seo:{
                title:"活動",
            }
        },
        children: [
            {
                path: 'list',
                name: 'event/list',
                component: () => import('@/views/explore/ExploreEventsPage.vue'),
                meta: { 
                    requiresAuth: false ,
                    seo:{
                        title:"活動探索",
                    }
                },
            },
            {
                path: 'detail/:event_code',
                name: 'event/detail',
                component: () => import('@/views/event/EventPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: false ,
                    seo:{
                        title:"活動資訊",
                    },
                    
                },
               
            },
            {
                path: 'ticket/:event_code',
                name: 'event/ticket',
                component: () => import('@/views/event/EventTicketPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    seo:{
                        title:"活動票券報名",
                    },
                    toolbar:{
                        title:'活動票券報名',
                        showBack:true,
                        backTo:{name:'event/detail',param:"event_code"}
                    }
                },
               
            },
            {
                path: 'confirm/:event_code',
                name: 'event/confirm',
                component: () => import('@/views/event/EventConfirmPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    seo:{
                        title:"報名票券確認",
                    },
                    toolbar:{
                        title:'報名票券確認',
                        showBack:true,
                        backTo:{name:'event/ticket',param:"event_code"}
                    }
                },
               
            },
            {
                path: 'form/:order_code',
                name: 'event/form',
                component: () => import('@/views/event/EventFormPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    seo:{
                        title:"填寫報名表",
                    },
                    toolbar:{
                        title:'填寫報名表',
                        showBack:true,
                        backTo:{name:'ticket/list'}
                    }
                },
            },
            
        ]
    },
   
];