// https://github.com/vuelidate/vuelidate/tree/next/packages/validators/src/withMessages
import { ref,toRefs, reactive, computed } from 'vue';
// import { required, email,helpers ,createI18nMessage} from '@vuelidate/validators'
import * as validators from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from 'vue-i18n'
 
const __init = () => {
    return {
       
       
    }
}

  function createNestedObject(obj, keys, index,rules) {
    const key = keys[index];

    if (index === keys.length - 1) {
      obj[key] = rules;
    } else {
      obj[key] = obj[key] || {};
      createNestedObject(obj[key], keys, index + 1,rules);
    }
  }
const buildRules = (fields)=>{
    let result = {}
    if(Array.isArray(fields)){
        fields.forEach(x => {
            if(x.rules != undefined){
                let keys = x.column.split('.')
                createNestedObject(result, keys, 0,x.rules);
                 
                
            }
            
        });
    }else if(typeof fields ==='object'){
        //already formatted
        result = {...fields}
    }
    return result
}

export function useCustomValidation() {
    const {t} = useI18n();
    const messagePath = (x) => {
        let key = x.$validator?x.$validator:x.$params.type
        return `validations.${key}`}
    const withI18nMessage = validators.createI18nMessage({ t,messagePath })
    const phone = validators.helpers.withMessage(
        ({$pending, $invalid, $params, $model}) => t("validations.phone"),
        (v) => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(v) && v.length>=7 || !v
    )
    const mobile = validators.helpers.withMessage(
        ({$pending, $invalid, $params, $model}) => t("validations.mobile"),
        (v) => /^09[0-9]{8}$/g.test(v)  || !v 
    )
    const hasUppercase = validators.helpers.withMessage(
        ({$pending, $invalid, $params, $model}) => t("validations.hasUppercase"),
        (v) => /[A-Z]/.test(v)  || !v 
    )
    const hasLowercase = validators.helpers.withMessage(
        ({$pending, $invalid, $params, $model}) => t("validations.hasLowercase"),
        (v) => /[a-z]/.test(v)  || !v 
    )
    const hasSpecial = validators.helpers.withMessage(
        ({$pending, $invalid, $params, $model}) => t("validations.hasSpecial"),
        (v) => /[#?!@$%^&*-]/.test(v)  || !v 
    )
    const hasNumber = validators.helpers.withMessage(
        ({$pending, $invalid, $params, $model}) => t("validations.hasNumber"),
        (v) => /[0-9]/.test(v)  || !v 
    )
   
    const helpers = validators.helpers

    const required = withI18nMessage(validators.required)
    const email = withI18nMessage(validators.email)
    const alphaNum = withI18nMessage(validators.alphaNum)
    const alpha = withI18nMessage(validators.alpha)

    const numeric = withI18nMessage(validators.numeric)
    const integer = withI18nMessage(validators.integer)

    const sameAs = withI18nMessage(validators.sameAs, { withArguments: true })
    const minLength = withI18nMessage(validators.minLength, { withArguments: true })
    const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
    const minValue = withI18nMessage(validators.minValue, { withArguments: true })
    const maxValue = withI18nMessage(validators.maxValue, { withArguments: true })
    const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })

    function dateFormat(date){
        let d =new Date(date)
        return `${d.toLocaleDateString('zh-TW', {  year: 'numeric', month: 'long', day: 'numeric' })}  ${d.toLocaleTimeString('zh-TW',{hour:"2-digit",minute:"2-digit",hour12:false})}`
       
    }
    const minDate = withI18nMessage((param) => helpers.withParams( { min: dateFormat(param) }, (value) => !helpers.req(param) || new Date(value) > new Date(param) ), { withArguments: true })
    const maxDate = withI18nMessage( (param) =>helpers.withParams( { max: dateFormat(param) }, (value) => !helpers.req(param) || new Date(value) < new Date(param) ), { withArguments: true })  
    const minEqualDate = withI18nMessage((param) => helpers.withParams( { min: dateFormat(param) }, (value) => !helpers.req(param) || new Date(value) >= new Date(param) ), { withArguments: true })
    const maxEqualDate = withI18nMessage( (param) =>helpers.withParams( { max: dateFormat(param) }, (value) => !helpers.req(param) || new Date(value) <= new Date(param) ), { withArguments: true })  

    const createValidation = (fields,data)=>{
        let rules = buildRules(fields)

        let v$ = useVuelidate(rules, data);

        return v$
    }
    function focusOnError(v$$, fields){
        let name = v$$.value.$errors[0].$property
        let item = fields.find(x=>x.column == name)
        item.element.focus()
    }
    return {
        createValidation,buildRules,useVuelidate,focusOnError,helpers,mobile,phone,required,email,alphaNum,numeric,integer,alpha,minDate,maxDate,minEqualDate,maxEqualDate,
        sameAs,minLength,maxLength,minValue,maxValue,requiredIf,
        hasUppercase,hasSpecial,hasLowercase,hasNumber
    };
}
