
export default [
    {
        path: '/organizer',
        redirect:'/organizer/detail',

        props: true,
        meta: { 
            requiresAuth: false ,
            seo:{
                title:"主辦單位",
            }
        },
        children: [
            {
                path: 'list',
                name: 'organizer/list',
                component: () => import('@/views/explore/ExploreCompaniesPage.vue'),
                meta: { 
                    requiresAuth: false ,
                    seo:{
                        title:"探索主辦單位",
                    }
                },
            },
            {
                path: 'donate/:company_code',
                name: 'organizer/donate',
                component: () => import('@/views/donate/DonationFormPage.vue'),
                props:true,
                meta: { 
                    requiresAuth: true ,
                    seo:{
                        title:"奉獻",
                    }
                },
            },
            {
                path: 'donate/:company_code/speed',
                name: 'organizer/donate/speed',
                component: () => import('@/views/donate/DonationSpeedFormPage.vue'),
                props:true,
                meta: { 
                    requiresAuth: false ,
                    seo:{
                        title:"快速金流",
                    }
                },
            },
            {
                path: 'detail/:company_code',
                name: 'organizer/detail',
                component: () => import('@/views/organizer/OrgMainPage.vue'),
                props:true,
                meta: { 
                    requiresAuth: false ,
                    seo:{
                        title:"主辦單位",
                    }
                },
            },
            
        ]
    },
   
];