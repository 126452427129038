
export default [
    {
        path: '/ticket',
        redirect:'/ticket/list',

        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"我的票券",
            }
        },
        children: [
            {
                path: 'list',
                name: 'ticket/list',
                component: () => import('@/views/ticket/TicketListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    seo:{
                        title:"票券列表",
                    }
                },
            },
            {
                path: 'checkin/:order_code',
                name: 'ticket/checkin',
                component: () => import('@/views/ticket/TicketCheckinPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    seo:{
                        title:"票券資訊",
                    },
                    toolbar:{
                        title:'票券資訊',
                        showBack:true,
                        backTo:{name:'ticket/list'}
                    }
                },
            },
          
          
        ]
    },
   
];