import { reactive,ref} from 'vue';
import { defineStore } from 'pinia'
import { useApiCall } from '@/components/composables/api'; 
const initState = ()=>{
    return {
        uid  :"",
        utkn  :"",
        name  :"",
        avatar  :"",
        is_verified:false,
        
        
   }
}

export const useUserStore = defineStore('user', () => {
    const api = useApiCall()
    const user = reactive(initState())
    const whereiwas = reactive({
        fullpath:"",
        query:{},
        params:{}
    })
    const setUserToekn =  (value) => {
        user.utkn = value
    }
    const setWhereiwas =  (value) => {
        whereiwas.fullpath = value.fullpath
        whereiwas.query = value.query
        whereiwas.params = value.params
    }
    const setUserProfile =  (value) => {
        user.uid = value.id
        user.name = value.name.length>0?value.name : value.nickname
        user.avatar = value.avatar
        user.is_verified = value.is_verified
    }
    
    const getMyProfile = async () => {
        try {
            let data =  await api.getApi("me/profile/simple") 
             if(data.status){

                setUserProfile(data.user)
                return true
            }else{
                return false
            }
        
        } catch (error) {
            console.log("user store getMyProfile error:",error)
            return false
        }
    }
    const logout =  () => {
        Object.assign(user,initState())
    }
    return { user,whereiwas,
        getMyProfile,
        setUserToekn,
        setUserProfile,setWhereiwas,
        logout}
},{ persist: true})
