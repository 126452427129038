
export default [
    {
        path: '/donate',
        redirect:'/donate/list',

        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"我的奉獻",
            }
        },
        children: [
            {
                path: 'list',
                name: 'donate/list',
                component: () => import('@/views/donate/DonateListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    seo:{
                        title:"奉獻列表",
                    }
                },
            },
           
          
          
        ]
    },
   
];