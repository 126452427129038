
export default [
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/account/RegisterPage.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/account/LoginPage.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/account/Logout.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/login_return',
        name: 'login_return',
        component: () => import('@/views/account/LoginReturn.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/verify',
        name: 'verify',
        component: () => import('@/views/account/VerifyUserPage.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/account/ForgotPasswordPage.vue'),
        meta: { 
            requiresAuth: false ,
            seo:{
                title:"重設密碼"
            }
        },
    },
    {
        path: '/facebook/redirect',
        name: 'facebook/redirect',
        component: () => import('@/views/account/FacebookRedirect.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
   
];